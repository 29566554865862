<template>
  <div>
    <Navbar @search="handleSearch" />
    <div class="container">
      <div class="row">
        <template v-if="loading">
          <div v-for="n in 5" :key="n" class="col-12">
            <VSkeletonLoader type="card" />
          </div>
        </template>
        <template v-else>
          <div
            v-for="therapist in therapists"
            :key="therapist.therapistId"
            class="col-12"
          >
            <CardItem
              :profile-image="getPhoto(therapist)"
              :name="therapist.name"
              :profession="therapist.additionalDetail"
              :price="therapist.sessionValue"
              :description="therapist.bio"
              :available-times="therapist.freeAppointments"
              :rating="therapist.rating"
              :reviews-count="therapist.reviewsCount"
              :therapist-id="therapist.therapistId"
              :abrabio-number="therapist.abrabioNumber"
              :formed-by-parmagnetico="therapist.formedByParmagnetico"
            />
          </div>
        </template>
        <div
          v-if="therapists.length === 0 && !loading && !loadingMore"
          class="no-results"
        >
          Por favor, altere a sua busca ou filtros e tente novamente.
          <img
            src="@/assets/page-not-found.png"
            alt="No
          results"
            width="120px"
          />
        </div>
        <div
          v-if="!moreResultsAvailable && therapists.length > 0"
          class="end-of-results"
        >
          Fim dos resultados.
        </div>
        <div v-if="therapists.length > 0" class="load-more-wrapper">
          <button
            class="load-more-button"
            :class="{ 'disabled-button': !moreResultsAvailable }"
            :disabled="!moreResultsAvailable || loadingMore"
            @click="loadMoreTherapists"
          >
            <template v-if="loadingMore && !loading">
              <VProgressCircular indeterminate size="24" />
              <span>Carregando...</span>
            </template>
            <template v-else> Carregar mais </template>
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/domains/find-therapist/nav-bar/NavBar.vue";
import CardItem from "@/domains/find-therapist/card-item/CardItem.vue";
import Footer from "@/domains/find-therapist/footer/Footer.vue";
import { findNextTenPublicTherapists } from "@/services/external/therapists-service";
import { VSkeletonLoader } from "vuetify/lib";

export default {
  name: "FindTherapist",
  components: {
    Navbar,
    CardItem,
    Footer,
    VSkeletonLoader,
  },
  data: () => ({
    hours: ["08:00", "09:00", "10:00", "11:00"],
    therapists: [],
    pageNumber: 1,
    searchWord: null,
    location: null,
    loading: false,
    loadingMore: false,
    moreResultsAvailable: true,
  }),
  async created() {
    this.loading = true;
    const therapists = await this.getTherapists();
    this.therapists = therapists;
    this.loading = false;
  },

  methods: {
    async getTherapists() {
      const result = await findNextTenPublicTherapists(
        this.pageNumber,
        this.searchWord,
        this.location
      );
      return result.data;
    },
    async handleSearch({ searchWord, location }) {
      this.loading = true;
      this.searchWord = searchWord;
      this.location = location;
      this.pageNumber = 1;
      const therapists = await this.getTherapists();
      if (therapists.length === 0) {
        this.moreResultsAvailable = false;
      } else {
        this.moreResultsAvailable = true;
      }
      this.therapists = therapists;
      this.loading = false;
    },
    async loadMoreTherapists() {
      this.loadingMore = true;
      this.pageNumber++;
      const nextTherapists = await this.getTherapists();
      this.therapists = [...this.therapists, ...nextTherapists];
      if (nextTherapists.length === 0) {
        this.moreResultsAvailable = false;
      }
      this.loadingMore = false;
    },

    getPhoto(therapist) {
      if (therapist.photo) {
        return therapist.photo;
      }
      return require("@/assets/user.svg");
    },
  },
};
</script>

<style>
.container {
  margin-top: 20px;
}

.load-more-button {
  background-color: transparent;
  color: #265acc;
  border: 1px solid #265acc;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.load-more-button:hover {
  background-color: #265acc;
  color: white;
}

.disabled-button {
  background-color: #e0e0e0;
  color: #9d9d9d;
  border: 1px solid #e0e0e0;
  cursor: not-allowed;
}

.disabled-button:hover {
  background-color: #e0e0e0;
  color: #9d9d9d;
}

.load-more-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.end-of-results {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  color: #9d9d9d;
  font-size: 12px;
  font-weight: 400;
}

.no-results {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  color: #9d9d9d;
  font-weight: 400;
  min-height: 200px;
  align-items: center;
  gap: 16px;
}
</style>
