<template>
  <div class="card">
    <div class="card-left">
      <div v-if="false" class="premium-tag">PREMIUM</div>
      <div class="card-left__info">
        <img :src="profileImage" alt="Profile" class="profile-image" />
        <div class="card-left__stack">
          <StarRating :rating="rating" :reviews-count="reviewsCount" />
          <div class="card-name">
            <span>{{ name }}</span>
            <LogoIpm v-if="formedByParmagnetico" style="width: 32px" />
          </div>
          <span v-if="abrabioNumber" class="register-number"
            >Nº ABRABIO: {{ abrabioNumber }}</span
          >
          <span class="card-profession">{{ elipsisText(profession) }}</span>
          <span class="card-price">R$ {{ price }}</span>
        </div>
      </div>
      <div class="card-description">
        <p>{{ elipsisText(description) }}</p>
      </div>
      <div>
        <button @click="goToTherapistProfile">
          <span> Saiba mais</span>
          <v-icon style="color: #fff">mdi-chevron-right</v-icon>
        </button>
      </div>
    </div>

    <div class="card-right">
      <div class="card-header">
        <div @click="previousDay">
          <v-icon class="card-header__icon">mdi-chevron-left</v-icon>
        </div>

        {{ formatDate(currentDate) }}
        <div @click="nextDay">
          <v-icon class="card-header__icon">mdi-chevron-right</v-icon>
        </div>
      </div>
      <div class="times-info">Horários disponíveis (Fuso Brasília)</div>
      <ul v-if="filteredAvailableTimes.length > 0">
        <li
          v-for="time in filteredAvailableTimes"
          :key="time"
          @click="selectTime(time)"
        >
          {{ time.time }}
        </li>
      </ul>
      <div v-if="filteredAvailableTimes.length === 0" class="empty-times">
        <span>Nenhum horário disponível.</span>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "@/domains/find-therapist/star-rating/StarRating.vue";
import LogoIpm from "@/components/svg/LogoIPM.vue";
export default {
  name: "CardItem",
  components: {
    StarRating,
    LogoIpm,
  },
  props: {
    profileImage: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    profession: {
      type: String,
      required: false,
      default: "",
    },
    price: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    availableTimes: {
      type: Array,
      required: true,
    },
    rating: {
      type: Number,
      required: true,
    },
    reviewsCount: {
      type: Number,
      required: true,
    },
    therapistId: {
      type: Number,
      required: true,
    },
    abrabioNumber: {
      type: String,
      default: null,
    },
    formedByParmagnetico: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentDate: new Date(),
    filteredAvailableTimes: [],
  }),
  computed: {
    isLogged() {
      return this.$store.getters["login/isLogged"];
    },
  },
  created() {
    const nextDayWithAvailableTimes = this.availableTimes[0];
    if (nextDayWithAvailableTimes) {
      console.log(nextDayWithAvailableTimes.day);
      const day = nextDayWithAvailableTimes.day.split("/");
      this.currentDate = new Date(day[2], day[1] - 1, day[0]);
    } else {
      this.currentDate.setDate(this.currentDate.getDate() + 1);
    }
    const foundDate = this.availableTimes.filter(
      (data) => data.day === this.currentDate.toLocaleDateString("pt-BR")
    )[0];
    if (foundDate) {
      this.filteredAvailableTimes = foundDate.times;
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    nextDay() {
      this.currentDate.setDate(this.currentDate.getDate() + 1);
      const dateFound = this.availableTimes.filter(
        (data) => data.day === this.currentDate.toLocaleDateString("pt-BR")
      )[0];
      if (dateFound) {
        this.filteredAvailableTimes = dateFound.times;
      } else {
        this.filteredAvailableTimes = [];
      }
    },
    previousDay() {
      this.currentDate.setDate(this.currentDate.getDate() - 1);
      const dateFound = this.availableTimes.filter(
        (data) => data.day === this.currentDate.toLocaleDateString("pt-BR")
      )[0];
      if (dateFound) {
        this.filteredAvailableTimes = dateFound.times;
      } else {
        this.filteredAvailableTimes = [];
      }
    },
    formatDate(date) {
      const options = {
        weekday: "long",
        month: "numeric",
        day: "numeric",
      };
      const formattedDate = date.toLocaleDateString("pt-BR", options);
      const capitalizedDay = this.capitalizeFirstLetter(
        formattedDate.split(",")[0]
      );
      return capitalizedDay + formattedDate.slice(formattedDate.indexOf(","));
    },

    selectTime(time) {
      const appointmentDetails = {
        appointmentId: time.id,
      };

      localStorage.setItem(
        "appointmentDetails",
        JSON.stringify(appointmentDetails)
      );

      this.$router.push({
        path: this.isLogged ? "home" : "login",
      });
    },
    goToTherapistProfile() {
      this.$router.push({
        path: `find-therapist/${this.therapistId}`,
      });
    },

    elipsisText(text) {
      if (!text) return text;
      const limit = 150;
      if (text.length > limit) {
        return text.slice(0, limit) + "...";
      }
      return text;
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: white;
}

.card-left {
  padding: 30px;
  flex: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.card-left__info {
  display: flex;
  gap: 20px;
}

.card-description {
  flex: 1;
}

.card-left__stack {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.premium-tag {
  position: absolute;
  top: 17px;
  left: -26px;
  background-color: #00b74a;
  padding: 5px 30px;
  border-radius: 2px;
  color: white;
  font-weight: 500;
  rotate: -45deg;
  font-size: 11px;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 20px;
}

.card-name {
  color: #265acc;
  font-size: 22px;
  font-weight: 900;
  display: flex;
  align-items: center;
  gap: 4px;
}

.card-profession {
  color: #9d9d9d;
  font-weight: 400;
}
.card-price {
  color: #454545;
  font-weight: 700;
}

h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

.card-right {
  flex: 1;
  padding: 10px 20px;
  border-left: 1px solid #e0e0e0;
  margin-bottom: 8px;
}

.card-header {
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
}

.card-header__icon {
  color: #0056b3;
  cursor: pointer;
  font-size: 32px;
}
.times-info {
  text-align: center;
  color: #9d9d9d;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0 32px;
  height: 175px;
  overflow: auto;
}

li {
  padding: 10px 0;
  background-color: #f8f8f8;
  text-align: center;
  margin-top: 8px;
  border-radius: 8px;
  font-size: 18px;
  color: #265acc;
  font-weight: 600;
  cursor: pointer;
}

li:last-child {
  border-bottom: none;
}
button {
  background-color: #265acc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  min-width: 200px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:hover {
  background-color: #0056b3;
}

/* For Webkit browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9d9d9d;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.empty-times {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #9d9d9d;
  font-weight: 400;
  font-size: 12px;
  height: 150px;
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
  }
  .card-left {
    padding: 20px;
  }
  .card-left__info {
    flex-direction: column;
    align-items: center;
  }
  .card-left__stack {
    align-items: center;
  }
  .card-left__stack > * {
    text-align: center;
  }
  .card-right {
    border-left: none;
    border-top: 1px solid #e0e0e0;
  }
  .card-header {
    gap: 8px;
  }
  .card-header__icon {
    font-size: 24px;
  }
  .times-info {
    margin-bottom: 0;
  }
  ul {
    padding: 0;
    height: 100%;
  }
  li {
    padding: 10px 0;
    margin-top: 0;
    border-radius: 0;
    font-size: 16px;
  }
  .empty-times {
    height: 100%;
  }
  button {
    margin: auto;
  }
}
.register-number {
  font-size: 12px;
  color: #9d9d9d;
  font-weight: 400;
}
</style>
